'use client'

import styles from './../styles/not-found.module.scss'
import {useRouter} from "next/navigation";

function NotFoundPage() {
  const router = useRouter()

  return (
    <div className={styles['not-found']}>
      <div className={styles['heading-text']}>
        <h1>
          <span>404</span>
        </h1>
      </div>
      <div className={styles['heading-text']}>
        <h5>
          <span>Page Not Found</span>
        </h5>
        <div className={styles['text-lead']}>
          <p>It seems that page you are looking for no longer exists.</p>
        </div>
        <div className={styles['divider-wrapper']}>
          <hr />
        </div>
      </div>
      <div className={styles['empty-space']} />
      <span className={styles['btn-container']}>
        <a onClick={() => router.push('/')} className={styles['custom-link']}>
          <i className="fa fa-arrow-left3"></i>
          Back Homepage
        </a>
      </span>
    </div>
  )
}

export default NotFoundPage